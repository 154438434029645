import * as React from "react";
import { css } from "@emotion/core";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";

import Layout from "../components/Layout";
import Seo from "../components/Seo";
import TextWrapper from "../components/TextWrapper";

const highlihtedStyle = css`
  font-weight: bold;
`;

const iframeStyle = css`
  max-width: 100%;
  display: block;
  margin: 0 auto;
`;

const photoContainer = (theme) => css`
  ${theme.typography.small};
  max-width: 550px;
  margin: 0 auto;
  text-align: center;

  p {
    margin: 0;
  }
`;

const IndexPage = ({ data }) => {
  const { mural, mapa, szczygiel, siedziba } = useStaticQuery(
    graphql`
      query {
        mural: file(relativePath: { eq: "mural.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1500) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        mapa: file(relativePath: { eq: "mapa_grunwald_2.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1500) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        szczygiel: file(relativePath: { eq: "marcin-szczygiel-pg.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1500) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        siedziba: file(relativePath: { eq: "siedziba.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1500) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  );
  return (
    <Layout includeBanner>
      <Seo />
      <TextWrapper>
        <p>
          <a href="#english">[SCROLL DOWN FOR ENGLISH]</a>
          <br />
          <a href="#ukrainian">[ПРОКРУТИТИ ВНИЗ ДЛЯ УКРАЇНСЬКОЇ ВЕРСІЇ]</a>
        </p>
        <p css={highlihtedStyle}>
          Centrum Aktywności Lokalnej Plac Grunwaldzki OD NOWA to miejsce,
          którego oferta skierowana jest do mieszkanek i mieszkańców osiedla
          Plac Grunwaldzki we Wrocławiu. Nasze działania adresujemy do osób w
          różnym wieku – dzieci i młodzieży, dorosłych i seniorów. Centrum jest
          przestrzenią zawiązywania się i pogłębiania więzi sąsiedzkich,
          wspierania oddolnych inicjatyw, organizacji wolontariatu i włączania w
          życie lokalnej społeczności osób i grup z różnych powodów wykluczonych
          lub mało aktywnych.
        </p>
        <p>
          Czeka tu na Was przyjazna przestrzeń, w której możecie porozmawiać o
          osiedlu, dowiedzieć się co ciekawego dzieje się w okolicy, włączyć się
          we wspólne działania, a także zrealizować własne inicjatywy. Nasz
          zespół czeka na Wasze pomysły i zawsze gotów jest wesprzeć Wasze
          przedsięwzięcia! Jesteśmy miejscem przyjaznym osobom LGBTQ+ – u nas
          jesteście u siebie. Możecie wpaść do nas ze swoim zwierzakiem – zawsze
          czeka na nie miska pełna wody. Centrum to także miejsce otwarte dla
          osób przybyłych do Wrocławia z Ukrainy – współpracują z nami osoby
          mówiące w języku ukraińskim, a coraz więcej naszych wydarzeń
          adresujemy właśnie do tej społeczności.
        </p>
        <p>
          Działania Centrum Aktywności Lokalnej na osiedlu Plac Grunwaldzki
          realizujemy dzięki dofinansowaniu ze środków Gminy Wrocław.
        </p>
        <div css={photoContainer}>
          <Img
            fluid={szczygiel.childImageSharp.fluid}
            alt="Grupa ludzi na spacerze słucha przewodnika"
          />
          <p>zdjęcie: Marcin Szczygieł</p>
        </div>

        <h2>Jak do nas dotrzeć?</h2>
        <p>
          Mieścimy się w podwórku przy ul. Marii Skłodowskiej-Curie 63a, na I
          piętrze byłej siedziby Wrocławskich Mieszkań – i to właśnie tutaj
          zapraszamy codziennie od poniedziałku do piątku w godzinach
          12:00-17:00. Informacje na temat wydarzeń, które organizujemy dla
          lokalnej społeczności w innych, niż regularne godziny działania
          Centrum znajdziecie w zakładce AKTUALNOŚCI lub na dedykowanej osiedlu
          stronie internetowej{" "}
          <a href="https://placgrunwaldzki.com">placgrunwaldzki.com</a>.
        </p>
        <div css={photoContainer}>
          <Img
            fluid={siedziba.childImageSharp.fluid}
            alt="Piętrowy ceglany budynek, siedziba fundacji"
          />
          <p>zdjęcie: Jerzy Wypych</p>
        </div>
        <p>
          Najbliższy przystanek tramwajowo-autobusowy nazywa się Kliniki –
          Politechnika Wrocławska i jest położony w odległości około 140 metrów
          od naszej siedziby. Trasa pomiędzy przystankiem i Centrum Aktywności
          Lokalnej to trzy przejścia przez jezdnię – przejście dla pieszych z
          sygnalizacją świetlną przez ulicę Marii Skłodowskiej-Curie, przejście
          dla pieszych z sygnalizacją świetlną przez ulicę Łukasiewicza oraz
          przejście dla pieszych bez sygnalizacji świetlnej przez kolejny
          odcinek ulicy Łukasiewicza. Wejście w podwórze, na którym znajduje się
          nasza siedziba wymaga przejścia koło restauracji Pasibus, minięcia
          muralu i przejścia odległości około 89 metrów.
        </p>
        <div css={photoContainer}>
          <Img
            fluid={mapa.childImageSharp.fluid}
            alt="Mapa Placu Grunwaldzkiego"
          />
        </div>
        <p>
          Na przystanek Kliniki – Politechnika Wrocławska dotrzecie tramwajami o
          numerze: 1, 2, 4, 10, 16, WLT oraz autobusami o numerze: 115, 145,
          146, 253, 255 i 315.
        </p>
        <p>Wskazówki jak do nas dotrzeć w Polskim Języku Migowym:</p>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/r7y26P22Z-o"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          css={iframeStyle}
        ></iframe>
        <p>
          Nasza lokalizacja na Mapach Google dostępna jest pod{" "}
          <a
            href="https://goo.gl/maps/k6Ec32APrMBQbv35A"
            target="_blank"
            rel="noopener noreferrer"
          >
            tym linkiem
          </a>
          .
        </p>
        <div css={photoContainer}>
          <Img
            fluid={mural.childImageSharp.fluid}
            alt="Mural na ścianie bocznej budynku"
          />
          <p>zdjęcie: Marta Sobala</p>
        </div>
        <h2>
          Dyżury sąsiedzkie - przyjdź i opowiedz nam o ważnych dla osiedla
          sprawach!
        </h2>
        <p>
          Centrum Aktywności Lokalnej oficjalnie ruszyło z początkiem sierpnia
          2021 roku, ale nasz zespół na osiedlu działa już trzeci rok. Wciąż
          chcemy jak najlepiej poznać naszych sąsiadów i nasze sąsiadki, dlatego
          też zapraszamy wszystkie osoby, które chciałyby się do nas przyłączyć,
          podzielić opinią, opowiedzieć o osiedlowych sprawach, które są dla
          nich istotne lub po prostu współtworzyć z nami program CALu.
        </p>
        <p>
          Od poniedziałku do piątku w godzinach 12:00-17:00 zapraszamy na Dyżur
          Sąsiedzki, podczas którego można porozmawiać z naszymi animator(k)ami
          na temat:
        </p>
        <ul>
          <li>
            współpracy przy organizacji działań na rzecz mieszkańców osiedla;
          </li>
          <li>możliwości zaangażowania się w osiedlowy wolontariat;</li>
          <li>pomysłów na własne inicjatywy, które moglibyśmy wesprzeć;</li>
          <li>
            aktualnej oferty i programu Centrum oraz innych podmiotów
            działających na naszym osiedlu.
          </li>
        </ul>
        <p>
          Żeby zadbać o komfort osób, które chciałyby się spotkać w ramach
          Dyżuru, zachęcamy do umawiania się telefonicznie pod numerem 71 757 15
          87 (w godzinach otwarcia Centrum).
        </p>

        <div lang="en" id="english">
          <h2>[ENGLISH VERSION]</h2>
          <p>
            Plac Grunwaldzki RENEWED Local Activity Centre is a place whose
            offer is addressed to the residents of the Plac Grunwaldzki housing
            estate in Wrocław. Our activities are created for people of all ages
            – children and youth, adults and seniors. The centre is a space for
            establishing and deepening neighbourhood ties, supporting grassroots
            initiatives, organising voluntary work and involving people and
            groups who are excluded or inactive for various reasons in the life
            of the local community.
          </p>
          <p>
            Here you will find a friendly space where you can talk about the
            neighbourhood, find out what's going on in the area, get involved in
            joint activities and implement your own initiatives. Our team is
            waiting for your ideas and is always ready to support your ventures!
            We are an LGBTQ+ friendly place – you are at home here. You can
            bring your pet – there is always a bowl full of water waiting for
            them. The centre is also open to people from Ukraine – we have
            Ukrainian speakers working with us and more and more of our events
            are addressed to this community.
          </p>
          <p>
            The activities of the Local Activity Centre in the Plac Grunwaldzki
            district are implemented thanks to funding from the Wrocław
            Municipality.
          </p>
          <div css={photoContainer}>
            <Img
              fluid={szczygiel.childImageSharp.fluid}
              alt="A group of people on a walk listen to a guide"
            />
            <p>photo: Marcin Szczygieł</p>
          </div>
          <h2>Directions on how to reach us</h2>
          <p>
            We are located in the backyard at 63a Maria Skłodowska-Curie Street,
            on the first floor of the former headquarters of Wrocławskie
            Mieszkania – and this is where we invite you every day from Monday
            to Friday between 12:00 and 5:00 pm. You can find information about
            the events we organise for the local community at times other than
            the Centre's regular operating hours in the NEWS section or on the
            estate's dedicated website{" "}
            <a href="https://placgrunwaldzki.com">placgrunwaldzki.com</a>.
          </p>
          <div css={photoContainer}>
            <Img
              fluid={siedziba.childImageSharp.fluid}
              alt="A single-story brick building, the foundation headquarter"
            />
            <p>photo: Jerzy Wypych</p>
          </div>
          <p>
            The nearest tram and bus stop is called Kliniki - Politechnika
            Wrocławska and is approximately 140 metres from our venue. The route
            between the tram and bus stop and the Centre involves three
            crossings of the road - a pedestrian crossing with traffic lights
            through Marii Skłodowskiej-Curie Street, a pedestrian crossing with
            traffic lights through Łukasiewicza Street and a pedestrian crossing
            without traffic lights through another section of Łukasiewicza
            Street. To enter the backyard where our venue is located requires
            walking past the Pasibus restaurant, passing the mural and walking a
            distance of approximately 89 metres.
          </p>
          <div css={photoContainer}>
            <Img
              fluid={mapa.childImageSharp.fluid}
              alt="Map of Grunwaldzki Square"
            />
          </div>
          <p>
            You will reach the stop of the Clinic - Wrocław University of
            Technology by trams no: 1, 2, 4, 10, 16, WLT and by buses no: 115,
            145, 146, 253, 255 i 315.
          </p>
          <p>Directions on how to reach us in Polish Sign Language:</p>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/r7y26P22Z-o"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            css={iframeStyle}
          ></iframe>
          <p>
            Our location on Google Maps is available at{" "}
            <a href="https://goo.gl/maps/k6Ec32APrMBQbv35A">this link</a>.
          </p>
          <div css={photoContainer}>
            <Img
              fluid={mural.childImageSharp.fluid}
              alt="Mural on the side wall of the building"
            />
            <p>photo: Marta Sobala</p>
          </div>
          <h2>
            Neighbourhood duty hours - come and tell us about issues important
            to the estate!
          </h2>
          <p>
            The Local Activity Centre officially started at the beginning of
            August 2021, but our team on the estate is now in its third year. We
            still want to get to know our neighbours and our neighbours as well
            as possible, so we welcome anyone who would like to join us, share
            an opinion, tell us about estate issues that are important to them
            or simply contribute to the Centre’s programme with us.
          </p>
          <p>
            From Monday to Friday from 12:00 to 17:00 we invite you to
            neighbours meetings, during which you can talk to our animator(s)
            about:
          </p>
          <ul>
            <li>
              cooperation in organising activities for the benefit of the
              residents of the neighbourhood;
            </li>
            <li>
              opportunities to get involved in neighbourhood volunteering;
            </li>
            <li>ideas for your own initiatives that we could support;</li>
            <li>
              the current offer and programme of the Centre and other entities
              active on our estate.
            </li>
          </ul>
          <p>
            In order to ensure the comfort of those who would like to make an
            appointment, we kindly encourage people to make an appointment by
            calling (+48) 71 7571587 (during the Centre's opening hours).
          </p>
        </div>

        <div lang="ua" id="ukrainian">
          <h2>[UA]</h2>
          <p>
            Центр Локальної Активності Грюнвальдська площа - це простір, послуги
            якого адресовані жителям і жителькам району Грюнвальд у Вроцлаві.
            Наша діяльність адресована людям різного віку: дітям і підліткам,
            дорослим і літнім людям. Центр є простором для зміцнення сусідських
            зв'язків, підтримки місцевих ініціатив і волонтерських організацій
            та включення в життя місцевих громад і груп, які з різних причин
            були мало активні.
          </p>
          <p>
            Тут на Вас чекає дружня атмосфера, де ви можете поговорити про
            місцеві ініціативи, дізнатися, що цікавого відбувається в цьому
            районі, включитися в спільну діяльність, а також реалізувати власні
            ініціативи. Наша команда чекає Ваших ідей і завжди готова підтримати
            Ваші починання! Ми дружнє місце для ЛГБТ+ - у нас Ви можете
            почуватися як вдома. Також можна приходити зі своїми чотирилапими
            друзями - у нас завжди є миска з водою для них. Центр також є
            місцем, відкритим для людей, котрі прибули до Вроцлава з України - з
            нами співпрацюють люди, котрі розмовляють українською мовою, і все
            більше і більше наших заходів ми адресуємо саме цій спільноті.
          </p>
          <p>
            Діяльність Центру Локальної Активності в районі Грюнвальдська площа
            фінансується з коштів гміни Вроцлав.
          </p>
          <div css={photoContainer}>
            <Img
              fluid={szczygiel.childImageSharp.fluid}
              alt="A group of people on a walk listen to a guide"
            />
            <p>photo: Marcin Szczygieł</p>
          </div>
          <h2>Як нас знайти?</h2>
          <p>
            Офіс нашої фундації знаходимося у дворі на вулиці Marii
            Skłodowskiej-Curie 63а, на першому поверсі - саме сюди ми запрошуємо
            Вас щодня з понеділка по п'ятницю з 12:00 до 17:00. Інформацію про
            заходи, які ми організовуємо для місцевої громади в інші, ніж
            звичайні години роботи центру, можна знайти на вкладці НОВИНИ або на
            сайті <a href="https://placgrunwaldzki.com">placgrunwaldzki.com</a>.
          </p>
          <div css={photoContainer}>
            <Img
              fluid={siedziba.childImageSharp.fluid}
              alt="A single-story brick building, the foundation headquarter"
            />
            <p>photo: Jerzy Wypych</p>
          </div>
          <p>
            Найближча трамвайно-автобусна зупинка називається Kliniki -
            Politechnika Wroclawska, яка знаходиться приблизно в 140 метрах від
            нашого офісу. Маршрут між зупинкою та Центром Місцевої Активності -
            це три переходи через проїжджу частину: пішохідний перехід зі
            світлофором через вулицю Marii Skłodowskiej-Curie, пішохідний
            перехід зі світлофором через вулицю Łukasiewicza та пішохідний
            перехід без світлофора через ще одну ділянку на вулиці Łukasiewicza.
            Аби пройти у двір, де знаходиться офіс нашої фундації потрібно
            пройти повз Pasibus і настінний мурал, а тоді пройти ще близько 89
            метрів.
          </p>
          <div css={photoContainer}>
            <Img
              fluid={mapa.childImageSharp.fluid}
              alt="Map of Grunwaldzki Square"
            />
          </div>
          <p>
            До зупинки Kliniki - Politechnika Wroclawska можна дістатися на
            трамваї з номером: 1, 2, 4, 10, 16, WLT і автобусами з номером: 115,
            145, 146, 253, 255 і 315.
          </p>
          <p>Тут Ви знайдете підказки як нас знайти Польською Мовою Жестів:</p>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/r7y26P22Z-o"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            css={iframeStyle}
          ></iframe>
          <p>
            А за цим посиланням Ви знайдете нашу геолокацію в{" "}
            <a
              href="https://goo.gl/maps/k6Ec32APrMBQbv35A"
              target="_blank"
              rel="noopener noreferrer"
            >
              Google Maps
            </a>
          </p>
          <div css={photoContainer}>
            <Img
              fluid={mural.childImageSharp.fluid}
              alt="Mural on the side wall of the building"
            />
            <p>photo: Marta Sobala</p>
          </div>
          <h2>
            Сусідські чергування - завітайте до нас, аби розповісти про важливі
            справи для району!
          </h2>
          <p>
            Центр Локальної Активності офіційно стартував з початку серпня 2021
            року, але наша команда працює в межах цього району Вроцлава вже
            третій рік. Оскільки ми прагнемо краще пізнати наших сусідів та
            сусідок, тому запрошуємо всіх людей, котрі хотіли б приєднатися до
            нас, поділитися своєю думкою, розповісти про потреби мешканців та
            мешканок, або ж спільно створити програму центру з нами.
          </p>
          <p>
            з понеділка по п'ятницю в год 12:00 до 17:00 ми запрошуємо Вас на
            чергування по сусідству, де ви можете поговорити з нашими
            аніматор(к)ами про:
          </p>
          <ul>
            <li>
              співпрацю в організації заходів в інтересах мешканців мікрорайону;
            </li>
            <li>можливості участі у волонтерських програмах;</li>
            <li>ваші власні ініціативи, які ми могли б підтримати;</li>
            <li>
              актуальні пропозиції та програми Центру та інших суб'єктів, що
              діють у нашому мікрорайоні.
            </li>
          </ul>
          <p>
            Щоб забезпечити комфорт для тих, хто хоче зустрітися в рамках
            чергування, ми рекомендуємо вам попередньо домовитися про зустріч в
            нашому офісі за телефоном: 71 757 15 87(в години роботи центру).
          </p>
        </div>
      </TextWrapper>
    </Layout>
  );
};
export default IndexPage;
